import { Accordion, AccordionDetails, AccordionSummary } from '@achieve/ascend'
import { TypographyLongForm, Typography } from 'components/Contentful'
import { ChevronDown } from 'react-feather'
import styles from './FaqAccordion.module.scss'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import dynamic from 'next/dynamic'
const OnboardingEmail = dynamic(() =>
  import('components/SignInForm/OnboardingEmail/OnboardingEmail')
)
const ArticleListCarousel = dynamic(() =>
  import('components/ArticleListCarousel/ArticleListCarousel')
)
const MiniCalculatorSection = dynamic(() =>
  import('components/MiniCalculatorSection/MiniCalculatorSection')
)
import Cookies from 'js-cookie'
import { useContext, useMemo, useRef } from 'react'
import { getProductAndAddDefaultUtmsToLink } from 'utils/queryManipulation'
import { preserveQueryParams } from 'utils/shared'
import { mainProductConfig } from 'services/contentful/helpers'
import { LayoutContext } from 'providers/LayoutProvider'

/**
 * @param {{
 *  question: object
 * }} props
 */
const FaqAccordion = ({
  formConfig,
  miniCalculatorConfig,
  articles,
  answer,
  question,
  setNumber,
  selected,
  setSelected,
  open = true,
  onChangeExpanded,
  index = 0,
  faqId = null,
  questionTextVariant,
  answerTextVariant,
  encryptionKey,
}) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const visitId = Cookies.get('eh_visit_id')
  const { state: { siteEnv } = {} } = useContext(LayoutContext)
  const trackRefs = useRef({
    cta: null,
    accordion: null,
  })
  let memoHref = useMemo(
    () =>
      getProductAndAddDefaultUtmsToLink(
        preserveQueryParams(miniCalculatorConfig?.ctaUrl, {}, visitId)
      ),
    [miniCalculatorConfig, visitId]
  )

  return (
    <>
      <TrackingWrapper
        track={{
          list_name: 'FAQ ACCORDION EXPANDED',
          click_id: !(selected === setNumber) ? 'Open' : 'Close',
          click_position: index,
          click_text: question?.json?.content?.[0]?.content[0]?.value || '',
          faqId: faqId,
          track_event: 'ui_click',
          event_action: 'ui_click',
          event_type: 'ui_click',
        }}
        onClick={onChangeExpanded}
      >
        <div ref={(el) => (trackRefs.current.accordion = el)}></div>
      </TrackingWrapper>
      <Accordion
        className={styles['accordion']}
        data-testid="faqs-accordion"
        expanded={selected === setNumber}
        id={`faq-${setNumber}`}
        key={setNumber}
        onChange={(event, expanded) => {
          setSelected(selected === setNumber ? '' : setNumber)
          onChangeExpanded?.(event, expanded)
          trackRefs?.current?.accordion?.click()
        }}
        style={{ display: open ? 'block' : 'none' }}
      >
        <AccordionSummary
          className={styles['accordion-summary']}
          data-testid="faqs-question"
          expandIcon={
            <ChevronDown
              className={selected === setNumber ? styles['expand-icon-selected'] : ''}
              size={isMobile ? 24 : 32}
            />
          }
        >
          <Typography
            className={styles['accordion-summary-text']}
            content={question}
            fontWeight="medium"
            variant={questionTextVariant ?? isMobile ? 'bodyLg' : 'headingMd'}
            component="h2"
          />
        </AccordionSummary>
        <AccordionDetails className={styles['accordion-detail']} data-testid="faqs-answer">
          {answer && (
            <TypographyLongForm
              content={answer}
              variant={answerTextVariant ?? 'bodySm'}
              component="p"
            />
          )}
          {articles?.length > 0 && (
            <ArticleListCarousel relatedArticles={articles} config={{ eyebrowsBlue: true }} />
          )}

          {miniCalculatorConfig && (
            <>
              <TrackingWrapper
                data-testid="faq-mini-calc-cta-tracking"
                track={{
                  list_name: 'DTI MINI TABLE CTA',
                  event_action: 'button_click',
                  click_text: `${
                    mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']
                  } | ${miniCalculatorConfig?.ctaCopy || 'See Your Options'}`,
                  click_type: 'Button Click',
                  click_url: memoHref,
                  nav_link_section: 'Faq Mini Tool CTA',
                  track_event: 'button_click',
                }}
              >
                <div ref={(el) => (trackRefs.current.cta = el)}></div>
              </TrackingWrapper>
              <MiniCalculatorSection
                {...{ ...miniCalculatorConfig, ctaUrl: memoHref }}
                ctaOnClick={() => trackRefs?.current?.cta?.click()}
              />
            </>
          )}
          {formConfig && <OnboardingEmail content={formConfig} encryptionKey={encryptionKey} />}
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export { FaqAccordion }
